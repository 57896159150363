import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <Box className={styles.footer} sx={{ py: { xs: 4, sm: 6 } }}>
      <Container maxWidth="lg">
        <Grid container spacing={3} justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" gutterBottom>
              Hayden McLeod
            </Typography>
            <Typography variant="body2">
              Software professional. Learner. Husband. Father. 
            </Typography>
            <Box>
              <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                <IconButton
                  component={Link}
                  href="https://www.linkedin.com/in/haydz/"
                  target="_blank"
                  aria-label="LinkedIn"
                  className={`${styles.iconButton} ${styles.whiteIcon}`}
                >
                  <LinkedInIcon fontSize="large" />
                </IconButton>
                <IconButton
                  component={Link}
                  href="https://github.com/haydz0r"
                  target="_blank"
                  aria-label="GitHub"
                  className={`${styles.iconButton} ${styles.whiteIcon}`}
                >
                  <GitHubIcon fontSize="large" />
                </IconButton>
                <IconButton
                  component={Link}
                  href="https://www.salesforce.com/trailblazer/haydz"
                  target="_blank"
                  aria-label="Trailhead"
                  className={`${styles.iconButton} ${styles.trailheadButton}`}
                >
                  <Box
                    component="img"
                    src="/images/trailhead-inverted.svg"
                    alt="Trailhead Icon"
                    className={styles.trailheadIcon}
                  />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
