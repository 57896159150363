import React from "react";
import { Box, Typography, Avatar, Grid, IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { styled } from '@mui/material/styles';

// Add this custom styled component
const LargeBodyText = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
  },
}));

const Home = () => {
  return (
    <Box
      id="home"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        position: "relative",
        marginTop: 10
      }}
    >
      <Typography variant="h4" gutterBottom>
        Hayden McLeod
      </Typography>

      <Avatar
        alt="Profile Picture"
        src="./images/avatar.jpeg"
        sx={{
          width: { xs: 180, sm: 280, md: 330 },
          height: { xs: 180, sm: 280, md: 330 },
          border: "2px solid grey",
          marginTop: { xs: 8, sm: 4 }
        }}
      />

      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ paddingTop: 1 }}
      >
        <Grid item>
          <LargeBodyText
            sx={{
              marginTop: { xs: 9, sm: 5 },
              textAlign: "center",
              lineHeight: 1.6,
            }}
          >
            I am a software professional with 11+ years experience, primarily specialising in Salesforce. 
          </LargeBodyText>

          <LargeBodyText
            sx={{
              marginTop: { xs: 4, sm: 2 },
              textAlign: "center",
              lineHeight: 1.6,
            }}
          >
            I value problem solving, simplifed communication, openness, determination, rapid iteration, perspective, humour and coaching.
          </LargeBodyText>

          <LargeBodyText
            sx={{
              marginTop: { xs: 4, sm: 2 },
              textAlign: "center",
              lineHeight: 1.6,
            }}
          >
            What gets me out of bed is solving meaningful problems that positively change our world with a team of people who care.
          </LargeBodyText>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: 4 }}
      >
        <IconButton
          component="a"
          href="https://www.linkedin.com/in/haydz/"
          target="_blank"
          aria-label="LinkedIn account link"
          sx={{ color: "black", "&:hover": { color: "#005582" } }}
        >
          <LinkedInIcon fontSize="large" />
        </IconButton>
        <IconButton
          component="a"
          href="https://github.com/haydz0r"
          target="_blank"
          aria-label="GitHub account link"
          sx={{ color: "#333", "&:hover": { color: "#000" } }}
        >
          <GitHubIcon fontSize="large" />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.salesforce.com/trailblazer/haydz"
          target="_blank"
          aria-label="Trailhead account link"
          sx={{ color: "#00A1E0", "&:hover": { color: "#007bb5" } }}
        >
          <Box
            component="img"
            src="/images/trailhead.svg"
            alt="Trailhead Icon"
            sx={{ width: 30, height: "auto" }}
          />
        </IconButton>
      </Grid>
    </Box>
  );
};

export default Home;
