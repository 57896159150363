import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import styles from "./Certifications.module.css";

const Certifications = () => {
  const items = [
    {
      name: "Salesforce Certified Administrator",
      issued: "Feb 2015",
      imagePath: "./images/certs/sca.png",
    },
    {
      name: "Salesforce Certified Advanced Administrator",
      issued: "Oct 2015",
      imagePath: "./images/certs/scaa.png",
    },
    {
      name: "Salesforce Certified Platform App Builder",
      issued: "Jul 2016",
      imagePath: "./images/certs/scpab.png",
    },
    {
      name: "Salesforce Certified Experience Cloud Consultant",
      issued: "Jun 2019",
      imagePath: "./images/certs/scec.png",
    },
    {
      name: "Salesforce Certified Sales Cloud Consultant",
      issued: "Aug 2016",
      imagePath: "./images/certs/scsc.png",
    },
    {
      name: "Salesforce Certified Service Cloud Consultant",
      issued: "Jul 2016",
      imagePath: "./images/certs/scscc.png",
    },
    {
      name: "Salesforce Certified Strategy Designer",
      issued: "June 2022",
      imagePath: "./images/certs/scsd.png",
    },
    {
      name: "Salesforce Certified User Experience Designer",
      issued: "Jun 2022",
      imagePath: "./images/certs/scued.png",
    },
    {
      name: "Salesforce Certified Platform Developer I",
      issued: "Sep 2020",
      imagePath: "./images/certs/scpd1.png",
    },
    {
      name: "Salesforce Certified Platform Developer II",
      issued: "Nov 2023",
      imagePath: "./images/certs/scpd2.png",
    },
    {
      name: "Salesforce Certified JavaScript Developer I",
      issued: "Oct 2020",
      imagePath: "./images/certs/scjd1.png",
    },
    {
      name: "Salesforce Certified Data Architect",
      issued: "Sep 2021",
      imagePath: "./images/certs/scda.png",
    },
    {
      name: "Salesforce Certified Development Lifecycle and Deployment Architect",
      issued: "Sep 2021",
      imagePath: "./images/certs/scdlad.png",
    },
    {
      name: "Salesforce Certified Identity and Access Management Architect",
      issued: "Nov 2021",
      imagePath: "./images/certs/sciama.png",
    },
    {
      name: "Salesforce Certified Integration Architect",
      issued: "Mar 2022",
      imagePath: "./images/certs/scia.png",
    },
    {
      name: "Salesforce Certified Sharing and Visibility Architect",
      issued: "Sep 2021",
      imagePath: "./images/certs/scsva.png",
    },
    {
      name: "Salesforce Certified Application Architect",
      issued: "August 2024",
      imagePath: "./images/certs/scaaa.png",
    },
    {
      name: "Salesforce Certified System Architect",
      issued: "Mar 2022",
      imagePath: "./images/certs/scsaa.png",
    }
  ];

  return (
    <Box id="certifications" className={styles.certificationsContainer} sx={{ py: { xs: 4, sm: 6, md: 8 } }}>
      <Typography variant="h4" className={styles.certificationsTitle} gutterBottom>
        Certifications
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card className={styles.card}>
              <CardMedia
                component="img"
                height="140"
                image={item.imagePath}
                alt={item.name}
                className={styles.cardMedia}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {item.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Issued: {item.issued}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Certifications;
