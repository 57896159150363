import "./App.css";
import Home from "./components/Home.tsx";
import About from "./components/About.tsx";
import Certifications from "./components/Certifications.tsx";
import Footer from "./components/Footer.tsx";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline, Container, Box } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import styles from "./components/App.module.css";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    h4: {
      fontFamily: "Roboto Slab, Roboto",
      fontWeight: 700,
      color: "#005ff7",
    },
    h5: {
      fontFamily: "Roboto Slab, Roboto",
      fontWeight: 500,
    },
    h6: {
      fontFamily: "Roboto Slab, Roboto",
    },
    body1: {
      fontFamily: "Roboto Slab, Roboto",
      fontSize: "1.25rem", // 20px
    },
    body2: {
      fontFamily: "Roboto Slab, Roboto",
      fontSize: "1.0625rem", // 17px
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "black",
          transition: "transform 0.3s ease-in-out, color 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.2)", // Reduced scale for better UX
            color: "#005ff7", // Match with h4 color for consistency
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#005ff7", // Consistent with h4 color
    },
  },
  spacing: 8, // Add this line to set a base spacing unit
});

// Define global styles
const globalStyles = {
  body: {
    fontFamily: "Roboto, Roboto",
    margin: 0,
    padding: 0,
    boxSizing: "border-box"
  },
  h4: {
    fontFamily: "Roboto Slab, Roboto",
    fontWeight: 700,
  },
  h6: {
    fontFamily: "Roboto Slab, Roboto",
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={globalStyles} />
      <CssBaseline />
      <Container maxWidth="lg">
        <Box className={styles.container}>
          <Home />
          <About />
          <Certifications />
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
