import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SchoolIcon from "@mui/icons-material/School";
import CodeIcon from "@mui/icons-material/Code";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import styles from "./About.module.css";
import { styled } from '@mui/material/styles';

// Add this custom styled component
const LargeBodyText = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem', // Increase this value for an even larger font size
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem', // Slightly smaller on mobile, but still larger than default
  },
}));

const CustomTimelineDot = styled(TimelineDot)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', // Grey drop shadow
  padding: theme.spacing(1),
}));

const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const items = [
    {
      title: "2013",
      icon: <SchoolIcon sx={{ color: "#005ff7", fontSize: isMobile ? 40 : 24 }} />,
      body: `
            Graduated Victoria University with a Bachelor of Commerce (Information Systems, E-Commerce) and landed my first job at Fronde
            where I worked on .NET, PHP, Java and Salesforce Applications. I had a pager which would go off at midnight, 2am, 3am, 4am, 
            and then I'd get up and be at work by 8am. The only application that didn't give me grief was a Salesforce app, which is
            where my passion for Salesforce started.
      `,
    },
    {
      title: "2015",
      icon: <CodeIcon sx={{ color: "#005ff7", fontSize: isMobile ? 40 : 24 }} />,
      body: `
            An opportunity arised to focus on implementing Salesforce applications for Davanti Consulting. This is where I cut my teeth and
            continued to learn about what makes a Salesforce implementation succeed. I worked with
            clients such as TradeMe, Ministry of Foreign Affairs and Trade, New Zealand Post and the IRD.
      `,
    },
    {
      title: "2017 to now",
      icon: <CorporateFareIcon sx={{ color: "#005ff7", fontSize: isMobile ? 40 : 24 }} />,
      body: `
          After consulting for a while, I wanted to own an implementation in a product led company, so I joined Xero. Although I left Xero for 
          a short contracting stint at the Ministry of Education in 2019, I boomeranged back to Xero with newfound insights into how we could 
          supercharge Xero's implementation. After being recognised as a top performer and leader, I was promoted to Principal Engineer where 
          I continue to lead the technical charge to help Xero succeed.
      `,
    },
  ];

  return (
    <Box id="about" className={styles.aboutContainer} sx={{ py: { xs: 4, sm: 6, md: 8 } }}>
      <Typography variant="h4" className={styles.aboutTitle} gutterBottom>
        About Me
      </Typography>
      {isMobile ? (
        <Grid container spacing={3} justifyContent="center">
          {items.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardMedia
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 60,
                    width: 60,
                    mt: 2,
                  }}
                >
                  {item.icon}
                </CardMedia>
                <CardContent sx={{ textAlign: "left" }}>
                  <Typography variant="h6" gutterBottom>
                    {item.title}
                  </Typography>
                  <LargeBodyText>{item.body}</LargeBodyText>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Timeline position="alternate">
          {items.map((item) => (
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                variant="h6"
                color="text.secondary"
              >
                {item.title}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <CustomTimelineDot>
                  {item.icon}
                </CustomTimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className={styles.timelineContent}>
                <Card className={styles.card}>
                  <LargeBodyText>{item.body}</LargeBodyText>
                </Card>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </Box>
  );
};

export default About;
